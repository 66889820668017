import { HttpErrorResponse, HttpInterceptorFn } from "@angular/common/http";
import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { catchError } from "rxjs";
import { ToastService } from "../toast/toast.service";
// import { toast } from 'ngx-sonner';

function convertObjectToString(obj: Record<string, string>) {
  const result = [];
  // Traverse the object and concatenate the messages
  for (const [key, value] of Object.entries(obj)) {
    result.push(`${key}: ${value}`);
  }
  return result.join(", ");
}

function checkForErrorMessage(err: any, originalMessage?: string): string {
  let message = "";

  if (err instanceof HttpErrorResponse) {
    // Check if the error has the expected structure
    if (err.error && err.error.Message) {
      message = err.error.Message;
    } else if (typeof err.error === "string") {
      message = err.error;
    }
  }

  // If no message was found, use the original message
  return message.length
    ? message
    : originalMessage || "An unknown error occurred.";
}

export const errorsInterceptor: HttpInterceptorFn = (req, next) => {
  const _router = inject(Router);
  const toastService = inject(ToastService);
  return next(req).pipe(
    catchError((err) => {
      let errorMessage = "Error Occured";
      const errorStatus = err.status as number;

      if (errorStatus >= 400 && errorStatus < 500) {
        switch (errorStatus) {
          case 0: // No Internet Connection or Network Error
            errorMessage =
              "No internet connection. Please check your network and try again.";
            toastService.addToast({
              type: "error",
              message: `
                <h2>Network Error 😭😭😭😭😭😭😭</h2>
                <p>${errorMessage}</p>
              `
            });
            break;
          case 401: // unauthorized
            errorMessage = checkForErrorMessage(
              err,
              "You are unauthorized to continue, login again or report"
            );
            toastService.addToast({
              type: "error",
              message: `
                <h2>Session-expired 🤪</h2>
                <p>${errorMessage}</p>
              `
            });
            _router.navigateByUrl("/login");
            break;
          case 502: // other 4xx errors
            errorMessage = checkForErrorMessage(err, "Bad request");
            toastService.addToast({
              type: "error",
              message: `
                <h2>Bad request</h2>
                <p>${errorMessage}</p>
              `
            });
            break;
          case 406: // other 4xx errors
            errorMessage = checkForErrorMessage(err);
            toastService.addToast({
              type: "error",
              message: `
                  <h2>Bad request 🤣</h2>
                  <p>${errorMessage}</p>
                `
            });
            _router.navigateByUrl("/login");
            break;
          default: // other 4xx errors
            errorMessage = checkForErrorMessage(err);
            toastService.addToast({
              type: "error",
              message: `
				  <h2>Problem oooo 😔</h2>
				  <p>${errorMessage}</p>
				  `
            });
            break;
        }
      }

      // backend errors
      if (errorStatus >= 500) {
        errorMessage = "Something went wrong, please try again or report";
        toastService.addToast({
          type: "error",
          message: `
				<h2>Backend error</h2>
				<p>${errorMessage}</p>
				`
        });
      }

      throw new Error(err);
    })
  );
};
