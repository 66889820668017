import { APP_INITIALIZER, ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { PreloadAllModules, provideRouter, withPreloading } from '@angular/router';

import { routes } from './app.routes';
import { TranslationService } from './services/translation/translation.service';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { authInterceptor } from './services/interceptors/auth.interceptor';
import { errorsInterceptor } from './services/interceptors/errors.interceptor';
import { t } from 'i18next';


function initializeTranslations(
  translationService: TranslationService,
): Promise<void> {
  return new Promise((resolve, reject) => {
    if (!translationService.isInitialized()) {
      translationService
        .initialise()
        .then(() => resolve())
        .catch(reject);
    } else {
      resolve();
    }
  });
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({eventCoalescing:true}),
    provideRouter(routes, withPreloading(PreloadAllModules)),
    {
      provide: APP_INITIALIZER,
      useFactory: (translationService: TranslationService) => {
        return () => {
          return initializeTranslations(translationService);
        };
      },
      multi: true,
      deps: [TranslationService],
    },
    provideHttpClient(
			withInterceptors([authInterceptor, errorsInterceptor]),
		)
  ]
  
};
