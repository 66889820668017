import { Routes } from '@angular/router';
import { isLoggedInGuard } from './services/guards/isLoggedIn.guard';

export const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./pages/pages.routes').then(m => m.PagesRoutes),
		canActivate: [isLoggedInGuard]
	},
	{
		path: 'login',
		loadComponent: () => import('./auth/login/login.component').then(m => m.LoginComponent),
	},
];

